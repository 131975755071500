import {mapActions, mapGetters} from "vuex";
const _ = require('lodash');
export default {
    data(){
        return{
            rememberActive:null,
            changeTime:[
                {
                    id:5,
                    title:'5 минут',
                },
                {
                    id:10,
                    title:'10 минут',
                },
                {
                    id:15,
                    title:'15 минут',
                },
                {
                    id:20,
                    title:'20 минут',
                },
                {
                    id:30,
                    title:'30 минут',
                },
                {
                    id:60,
                    title:'1 час',
                },
                {
                    id:120,
                    title:'2 часа',
                },
                {
                    id:180,
                    title:'3 часа',
                },
            ]

        }
    },
    computed:{
        ...mapGetters({
            profile:'profile/profile'
        })
    },
    watch:{
        profile(e){
            this.checkRememberActive(e);
        }
    },
    methods:{
        soundInformation(){
            if(this.rememberActive && this.rememberActive.length){
                const audio = new Audio(require('../../../../assets/sounds/ALARM_CLOCK.mp3'))
                audio.play();
            }
        },
        approvedNotification(item, approveStatus){
            let options = _.clone(this.profile.options);
            options.remember.map(itemRemember=>{
                if(itemRemember.notification_id === item.notification_id && approveStatus){
                    itemRemember.status = 1;
                }else if(itemRemember.notification_id === item.notification_id && !approveStatus){
                    itemRemember.status = 2;
                }
            });
            let payload = {
                user_id : this.profile.id,
                options
            }
            this.editUser(payload).then(()=>{
                if(approveStatus){
                    const itemIndex = this.rememberActive.indexOf(item);
                    this.rememberActive.splice(itemIndex, 1);
                }
            })
        },
        removeItemFromRememberActiveShow(id){
            this.rememberActive = this.rememberActive.filter(item => item.notification_id !== id)
        },
        checkRememberActive(e){
            if(e && e.options && e.options.remember.length){
                this.rememberActive = [];
                let actualTime = this.$moment();
                e.options.remember.map(item=>{
                    if(item.status === 0){
                        let activationTime = item.date + " " + item.time
                        activationTime = this.$moment(activationTime)
                        const diffTime = actualTime.diff(activationTime,'minutes')
                        if(diffTime >= 0 && diffTime <= 60){
                            item.dopTime = 5;
                            this.rememberActive.push({
                                ...item
                            })
                        }else if(diffTime >= 61){
                            this.approvedNotification(item, false)
                        }
                    }
                })
            }
            this.soundInformation();
        },
        changeFinishTime(item){
            let options = _.clone(this.profile.options);
            options.remember.map(itemRemember=>{
                if(itemRemember.notification_id === item.notification_id){
                    let activationTime = item.date + " " + item.time
                    itemRemember.time = this.$moment(activationTime).add(item.dopTime, 'minutes');
                    itemRemember.time = this.$moment(itemRemember.time).format('HH:mm')
                }
            });
            let payload = {
                user_id : this.profile.id,
                options
            }
            this.editUser(payload).then(()=>{
                this.getProfile();
            })
        },
        ...mapActions({
            editUser:'contacts/editUser',
            getProfile:'profile/getProfile',
        })
    }
}